<template>
  <div class="row m-4">
    <nav-pill></nav-pill>
    <div class="tab-content tab-space mt-4">
      <div id="craftBeer" class="tab-pane active">
        <div class="row">
          <editable-beer-card :beers="craftBeer" title="Craft Beer" collection="CraftBeer" @on-edit-beer="onEditCraftBeer" @on-remove-beer="onRemoveCraftBeer" @on-add-beer="onAddCraftBeer"/>
        </div>
      </div>
      <div id="realAle" class="tab-pane">
        <div class="row">
          <editable-beer-card :beers="realAle" title="Real Ale" collection="RealAle" @on-edit-beer="onEditAle" @on-remove-beer="onRemoveAle" @on-add-beer="onAddRealAle"/>
        </div>
      </div>
      <div id="liveMusic" class="tab-pane">
        <div class="row">
          <editable-live-music-card :beers="liveMusic" title="Live Music" collection="LiveMusic" @on-edit-item="onEditLiveMusic" @on-remove-item="onRemoveLiveMusic" @on-add-item="onAddLiveMusic" />
        </div>
      </div>
    </div>
    <update-beer-modal v-model="editBeer" @on-save="completeEditOrSave"/>
    <update-music-modal v-model="editMusic" @on-save="completeEditOrSave" />
    <remove-confirmation-modal @on-delete-confirmed="completeDeletion"></remove-confirmation-modal>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import {collection, getDocs} from "firebase/firestore";
import {firestore} from "@/firebase";
import EditableBeerCard from "@/pages/admin/EditableBeerCard";
import NavPill from "@/pages/admin/NavPill";
import UpdateBeerModal from "@/pages/admin/UpdateBeerModal";
import RemoveConfirmationModal from "@/pages/admin/RemoveConfirmationModal";
import { doc, addDoc, setDoc, deleteDoc, Timestamp } from "firebase/firestore";
import router from "@/router";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import EditableLiveMusicCard from "@/pages/admin/EditableLiveMusicCard";
import UpdateMusicModal from "@/pages/admin/UpdateMusicModal";
import flatpickr from "flatpickr";

export default {
  name: "AdminDashboard",
  components: {
    UpdateMusicModal,
    RemoveConfirmationModal,
    UpdateBeerModal,
    NavPill,
    EditableBeerCard,
    EditableLiveMusicCard
  },
  data() {
    return {
      craftBeer: [],
      realAle: [],
      liveMusic: [],
      editBeer: {},
      editMusic: {},
      updateOrEditFromCollection: "",
      delete: {},
      deleteFromCollection: ""
    }
  },
  async beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        getDocs(collection(firestore, "AllowedUsers")).then((allowedUsers) => {
        let matched = false;
        allowedUsers.forEach((doc) => {
            if (doc.data().email.toLowerCase() === getAuth().currentUser.email.toLowerCase()) {
              matched = true;
              router.replace({name: 'AdminDash'});
            }
          });

          if (!matched) router.replace({name: 'Landing'});
        });
      } else {
        router.replace({name: 'Landing'});
      }
    });

    const craftBeerSnapshot = await getDocs(collection(firestore, "CraftBeer"));
    craftBeerSnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      this.craftBeer.push(data);
    });

    const realAleSnapshot = await getDocs(collection(firestore, "RealAle"));
    realAleSnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      this.realAle.push(data);
    });

    const liveMusicSnapshot = await getDocs(collection(firestore, "LiveMusic"));
    liveMusicSnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      data.date = data.date.toDate();
      this.liveMusic.push(data);
    });
  },
  methods: {
    onAddCraftBeer() {
      this.updateBeerDataAndShowModal({}, 'CraftBeer')
    },
    onEditCraftBeer(index) {
      this.updateBeerDataAndShowModal(this.craftBeer[index], 'CraftBeer')
    },
    onRemoveCraftBeer(index) {
      this.updateDeleteItemAndShowModal(this.craftBeer[index], 'CraftBeer')
    },
    onAddRealAle() {
      this.updateBeerDataAndShowModal({}, 'RealAle')
    },
    onEditAle(index) {
      this.updateBeerDataAndShowModal(this.realAle[index], 'RealAle')
    },
    onRemoveAle(index) {
      this.updateDeleteItemAndShowModal(this.realAle[index], 'RealAle')
    },
    onAddLiveMusic() {
      this.editMusic = {};
      this.updateOrEditFromCollection = 'LiveMusic';
      (new Modal(document.getElementById('updateMusic'))).show();
    },
    onEditLiveMusic(index) {
      this.editMusic = this.liveMusic[index];
      this.updateOrEditFromCollection = 'LiveMusic';
      (new Modal(document.getElementById('updateMusic'))).show();
    },
    onRemoveLiveMusic(index) {
      this.updateDeleteItemAndShowModal(this.liveMusic[index], 'LiveMusic')
    },
    updateBeerDataAndShowModal(beer, collection) {
      this.editBeer = beer;
      this.updateOrEditFromCollection = collection;
      (new Modal(document.getElementById('updateBeer'))).show();
    },
    updateDeleteItemAndShowModal(item, collection) {
      this.delete = item;
      this.deleteFromCollection = collection;
      (new Modal(document.getElementById('deleteModal'))).show();
    },
    async completeEditOrSave(item) {
      if(item.date) {
        const fp = flatpickr(document.getElementById('date'), {
          enableTime: true,
          dateFormat: "d/m/Y, H:i",
        });
        item.date = Timestamp.fromDate(fp.selectedDates[0]);
      }

      if(item.id) {
        await setDoc(doc(firestore, this.updateOrEditFromCollection, item.id), item);
      } else {
        await addDoc(collection(firestore, this.updateOrEditFromCollection), item);
      }
      await this.completeRefresh(this.updateOrEditFromCollection)
    },
    async completeDeletion() {
      await deleteDoc(doc(firestore, this.deleteFromCollection, this.delete.id));
      await this.completeRefresh(this.deleteFromCollection)
    },
    async completeRefresh(collection) {
      switch(collection) {
        case "CraftBeer":
          await this.refreshCraftBeer()
          break;
        case "RealAle":
          await this.refreshRealAle()
          break;
        case "LiveMusic":
          await this.refreshLiveMusic()
          break;
      }
    },
    async refreshCraftBeer() {
      const craftBeerSnapshot = await getDocs(collection(firestore, "CraftBeer"));
      this.craftBeer = [];
      craftBeerSnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        this.craftBeer.push(data);
      });
    },
    async refreshRealAle() {
      const realAleSnapshot = await getDocs(collection(firestore, "RealAle"));
      this.realAle = [];
      realAleSnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        this.realAle.push(data);
      });
    },
    async refreshLiveMusic() {
      const liveMusicSnapshot = await getDocs(collection(firestore, "LiveMusic"));
      this.liveMusic = [];
      liveMusicSnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.date = data.date.toDate().toLocaleString();
        this.liveMusic.push(data);
      });
    }
  }
}
</script>

<style scoped>

</style>