<template>
  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="modal-default" aria-hidden="true">
    <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="modal-title-default">Are you sure you want to delete this?</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Like, really, really, completely, actually 100% undeniably sure? You can't undo it after!</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn bg-gradient-danger" @click="this.$emit('on-delete-confirmed')" data-bs-dismiss="modal">Delete</button>
          <button type="button" class="btn btn-link  ml-auto" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemoveConfirmationModal",
  emits: ['on-delete-confirmed']
}
</script>

<style scoped>

</style>