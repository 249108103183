<template>
  <div class="card">
    <div class="card-body">
      <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active"
              data-bs-toggle="tab"
              href="#craftBeer"
              role="tab"
              aria-selected="true">Craft Beer</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              data-bs-toggle="tab"
              href="#realAle"
              role="tab"
              aria-selected="false">Real Ale</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              data-bs-toggle="tab"
              href="#liveMusic"
              role="tab"
              aria-selected="false">Live Music</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "NavPill",
  mounted() {
    setNavPills();
  },
};
</script>
