<template>
  <div class="card">
    <div class="card-header">
      <h5 class="mb-0">{{ title }}</h5>
    </div>
    <div class="card-body pt-0">
      <ul class="list-group list-group-flush">
        <li class="list-group-item px-0">
          <div class="row align-items-center text-bold">
            <div class="col-2 ml-2">
              Name
            </div>
            <div class="col-2">
              Brand
            </div>
            <div class="col-2">
              ABV
            </div>
            <div class="col-2">
              Price Point 1
            </div>
            <div class="col-2">
              Price Point 2
            </div>
            <div class="col-2">
              Actions
            </div>
          </div>
        </li>
        <li v-for="({ name,  description, brand, abv, thirdPint, halfPint}, index) of beers" :key="index" class="list-group-item px-0">
          <div class="row align-items-center">
            <div class="col-2 ml-2">
              {{ name }} <br />
              <span class="text-sm">{{ description }}</span>
            </div>
            <div class="col-2">
              {{ brand }}
            </div>
            <div class="col-2">
              {{ abv }}
            </div>
            <div class="col-2">
              {{ thirdPint }}
            </div>
            <div class="col-2">
              {{ halfPint }}
            </div>
            <div class="col-2">
              <button type="button" class="btn btn-outline-warning btn-xs mb-0 m-1" @click="this.$emit('on-edit-beer', index)">
                Edit
              </button>
              <button type="button" class="btn btn-outline-danger btn-xs mb-0 m-1" @click="this.$emit('on-remove-beer', index)">
                Remove
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div class="row mt-4 d-flex">
        <div class="col-11">

        </div>
        <div class="col-1 align-items-end">
          <argon-button class="btn bg-gradient-success" @click="this.$emit('on-add-beer', collection)">Add</argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ArgonButton from "@/pages/admin/ArgonButton";
export default {
  name: "EditableBeerCard",
  components: {ArgonButton},
  emits: ['on-edit-beer', 'on-remove-beer', 'on-add-beer'],
  props: {
    title: {
      type: String,
      default: "Beer",
    },
    beers: {
      type: Array,
      required: true,
      name: String,
      description: String,
      brand: String,
      abv: String,
      thirdPint: String,
      halfPint: String
    },
    collection: String
  }
};
</script>
