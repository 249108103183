<template>
  <!-- #5e00f6 CRAFT BEER BACKGROUND
       #be0200 REAL ALE BACKGROUND -->
  <div class="mt-4 card h-100 mt-md-0" :style="'background-color:' + background">
    <div class="p-3 pb-0 card-header" :style="'background-color:' + background">
      <div class="d-flex align-items-center">
        <h6 class="text-7xl" style="font-family: 'Tangerine', serif;">{{ title }}</h6>
      </div>
    </div>
    <div class="px-3 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center justify-content-center text-dark" style="font-family: 'Acme', serif;">
          <thead>
            <tr>
              <th v-for="(header, index) of headers" :key="index" class="text-uppercase text-dark text-xxs font-weight-bolder ps-2">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="({ name, description, brand, abv, thirdPint, halfPint }, index) of beers" :key="index" >
              <td>
                <p class="mb-0 text-2xl font-weight-bold text-uppercase">
                  {{ name }}<br />
                <span class="text-lg font-italic text-capitalize">{{ description }}</span>
                </p>
              </td>
              <td>
                <p class="mb-0 text-lg font-weight-bold text-lg">{{ brand }}</p>
              </td>
              <td>
                <p class="mb-0 text-lg font-weight-bold text-md">{{ abv }}</p>
              </td>
              <td>
                <p class="mb-0 text-lg font-weight-bold text-md">{{ thirdPint }}</p>
              </td>
              <td>
                <p class="mb-0 text-lg font-weight-bold text-md">{{ halfPint }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeerCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      required: true,
    },
    beers: {
      type: Array,
      required: true,
      name: String,
      description: String,
      brand: String,
      abv: String,
      thirdPint: Number,
      halfPrint: Number
    },
    background: String
  },
};
</script>
