<template>
  <div class="modal fade" id="updateBeer" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="card card-plain">
            <div class="card-header pb-0 text-left text-bold">
              {{ cardTitle() }}
            </div>
            <div class="card-body">
              <form role="form text-left">
                <div class="row">
                  <label class="form-label">Name</label>
                  <argon-input id="name" type="text" :is-required="true" icon="fa fa-signature" icon-dir="left" v-model="inputVal.name" />
                </div>
                <div class="row">
                  <label class="form-label">Description</label>
                  <argon-input id="description" type="text" :is-required="true" icon="fa fa-list" icon-dir="left" v-model="inputVal.description" />
                </div>
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">Brand</label>
                    <argon-input id="brand" type="text" :is-required="true" icon="fa fa-copyright" icon-dir="left" v-model="inputVal.brand" />
                  </div>
                  <div class="col-6">
                    <label class="form-label">ABV</label>
                    <argon-input id="abv" type="text" :is-required="true" icon="fa fa-percent" icon-dir="left" v-model="inputVal.abv" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">Price Point 1</label>
                    <argon-input id="pp1" type="text" :is-required="true" icon="fa fa-money-bill-alt" icon-dir="left" v-model="inputVal.thirdPint" />
                  </div>
                  <div class="col-6">
                    <label class="form-label">Price Point 2</label>
                    <argon-input id="pp2" type="text" :is-required="true" icon="fa fa-money-bill-wave" icon-dir="left" v-model="inputVal.halfPint" />
                  </div>
                </div>
              </form>
            </div>
            <div class="card-footer text-end pt-0 px-lg-2 px-1">
              <button type="button" class="btn bg-gradient-success" @click="this.$emit('on-save', modelValue)" data-bs-dismiss="modal">Save</button>
              <button type="button" class="btn btn-link  ml-auto" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/pages/admin/ArgonInput";

export default {
  name: "UpdateBeerModal",
  emits: ['on-save'],
  components: {
    ArgonInput
  },
  props: {
    modelValue: Object
  },
  methods: {
    cardTitle() {
      if(this.modelValue?.name) {
        return "Editing " + this.modelValue.name;
      } else {
        return "Add a new item"
      }
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<style scoped>

</style>