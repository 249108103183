// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCcYy0agnCDV3naTY5LoBwoqVYtgvwzEXA",
    authDomain: "tfc-kowashita.firebaseapp.com",
    projectId: "tfc-kowashita",
    storageBucket: "tfc-kowashita.appspot.com",
    messagingSenderId: "5885950061",
    appId: "1:5885950061:web:36799293e544d657472764",
    measurementId: "G-96HRX1V8Q3"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);

export default {
    analytics,
    firestore
}