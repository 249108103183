<template>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" style=" background-image: url('https://lirp.cdn-website.com/590f13d7/dms3rep/multi/opt/IMG_0806-1920w.jpg');">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5>
                <div class="btn-wrapper text-center">
                  <a href="javascript:;" class="btn btn-neutral btn-icon btn-sm mb-0" @click="signIn">
                    <img class="w-30" src="../../assets/img/logos/google.svg"/>
                    Google
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapMutations } from "vuex";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import router from "@/router";
import {collection, getDocs} from "firebase/firestore";
import {firestore} from "@/firebase";

export default {
  name: "SignIn",
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    signIn() {
      const provider = new GoogleAuthProvider();

      const auth = getAuth();
      signInWithPopup(auth, provider).then((result) => {
            getDocs(collection(firestore, "AllowedUsers")).then((snapshot) => {
              let matched = false;
              snapshot.forEach((doc) => {
                if(doc.data().email.toLowerCase() === result.user.email.toLowerCase()) {
                  matched = true;
                  router.replace({ name: 'AdminDash' });

                }
              });

              if(!matched) router.replace({ name: 'Landing' });
            });
          }).catch(() => router.replace({ name: 'Landing' }));
    },
  },
};
</script>
