<template>
  <div class="row m-3 mb-3">
    <div class="col-lg-6 col-sm-6 col-6">
      <beer-card title="Craft Beer" :headers="['Beer', 'Brand', 'ABV', '1/3 Pint', '1/2 Pint']" :beers="craftBeer" background="#cdb0f6"/>
    </div>
    <div class="col-lg-6 col-sm-6 col-6">
      <div class="row">
        <beer-card title="Real Ale" :headers="['Beer', 'Brand', 'ABV', '1/2 Pint', '1 Pint']" :beers="realAle" background="#fe8b80"/>
      </div>
      <div class="row mt-3">
        <div class="card" style="background-color: #9ee4fc">
          <div class="card-header p-3 pb-0" style="background-color: #9ee4fc">
            <h6 class="mb-0 text-7xl" style="font-family: 'Tangerine', serif;">Live Music</h6>
          </div>
          <div class="card-body border-radius-lg p-3">
            <live-music-item v-for="({ name, date, price }, index) of liveMusic" :key="index" color="info" :title="name" icon="" :description="date.toDate().toLocaleString()" :price="price" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BeerCard from "@/pages/BeerCard";
import LiveMusicItem from "@/pages/LiveMusicItem";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from '../firebase/index';

export default {
  name: "MainDash",
  components: {
    LiveMusicItem,
    BeerCard
  },
  data() {
    return {
      craftBeer: [],
      realAle: [],
      liveMusic: []
    }
  },
  async beforeCreate() {
    const craftBeerSnapshot = await getDocs(collection(firestore, "CraftBeer"));
    craftBeerSnapshot.forEach((doc) => {
      this.craftBeer.push(doc.data());
    });

    const realAleSnapshot = await getDocs(collection(firestore, "RealAle"));
    realAleSnapshot.forEach((doc) => {
      this.realAle.push(doc.data());
    });

    const liveMusicSnapshot = await getDocs(collection(firestore, "LiveMusic"));
    liveMusicSnapshot.forEach((doc) => {
      this.liveMusic.push(doc.data());
    });
  }
}
</script>

<style scoped>

</style>