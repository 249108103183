<template>
  <div class="card">
    <div class="card-header">
      <h5 class="mb-0">{{ title }}</h5>
    </div>
    <div class="card-body pt-0">
      <ul class="list-group list-group-flush">
        <li class="list-group-item px-0">
          <div class="row align-items-center text-bold">
            <div class="col-3 ml-2">
              Band
            </div>
            <div class="col-3">
              Date & Time
            </div>
            <div class="col-3">
              Price
            </div>
            <div class="col-3">
              Actions
            </div>
          </div>
        </li>
        <li v-for="({ name,  date, price }, index) of beers" :key="index" class="list-group-item px-0">
          <div class="row align-items-center">
            <div class="col-3 ml-2">
              {{ name }}
            </div>
            <div class="col-3">
              {{ date.toLocaleString() }}
            </div>
            <div class="col-3">
              {{ price }}
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-outline-warning btn-xs mb-0 m-1" @click="this.$emit('on-edit-item', index)">
                Edit
              </button>
              <button type="button" class="btn btn-outline-danger btn-xs mb-0 m-1" @click="this.$emit('on-remove-item', index)">
                Remove
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div class="row mt-4 d-flex">
        <div class="col-11">

        </div>
        <div class="col-1 align-items-end">
          <argon-button class="btn bg-gradient-success" @click="this.$emit('on-add-item', collection)">Add</argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ArgonButton from "@/pages/admin/ArgonButton";
export default {
  name: "EditableLiveMusicCard",
  components: {ArgonButton},
  emits: ['on-edit-item', 'on-remove-item', 'on-add-item'],
  props: {
    title: {
      type: String,
      default: "Beer",
    },
    beers: {
      type: Array,
      required: true,
      name: String,
      description: String,
      brand: String,
      abv: String,
      thirdPint: String,
      halfPint: String
    },
    collection: String
  }
};
</script>
