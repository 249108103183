<template>
  <div class="modal fade" id="updateMusic" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="card card-plain">
            <div class="card-header pb-0 text-left text-bold">
              {{ cardTitle() }}
            </div>
            <div class="card-body">
              <form role="form text-left">
                <div class="row">
                  <label class="form-label">Name</label>
                  <argon-input id="name" type="text" :is-required="true" icon="fa fa-signature" icon-dir="left" v-model="inputVal.name" />
                </div>
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">Date</label>
                    <input id="date" class="form-control datepicker" placeholder="Please select date" type="text" v-model="inputVal.date" />
                  </div>
                  <div class="col-6">
                    <label class="form-label">Price</label>
                    <argon-input id="price" type="text" :is-required="true" icon="fa fa-money-bill" icon-dir="left" v-model="inputVal.price" />
                  </div>
                </div>
              </form>
            </div>
            <div class="card-footer text-end pt-0 px-lg-2 px-1">
              <button type="button" class="btn bg-gradient-success" @click="this.$emit('on-save', modelValue)" data-bs-dismiss="modal">Save</button>
              <button type="button" class="btn btn-link  ml-auto" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/pages/admin/ArgonInput";
import flatpickr from "flatpickr";

export default {
  name: "UpdateMusicModal",
  emits: ['on-save'],
  components: {
    ArgonInput
  },
  props: {
    modelValue: Object
  },
  mounted() {
    flatpickr(document.getElementById('date'), {
      enableTime: true,
      dateFormat: "d/m/Y, H:i",
    })
  },
  methods: {
    cardTitle() {
      if(this.modelValue?.name) {
        return "Editing " + this.modelValue.name;
      } else {
        return "Add a new item"
      }
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<style scoped>

</style>